<template>
  <header id="header" class="u-header u-scrolled">
    <div class="u-header__section py-1">
      <div id="logoAndNav" class="container">
        <nav
          class="
            js-mega-menu
            navbar navbar-expand-md
            u-header__navbar u-header__navbar--no-space
            hs-menu-initialized hs-menu-horizontal hs-pc-state
          "
        >
          <div class="w-lg-auto">
            <a
              class="navbar-brand pt-md-0"
              href="/"
              target="_parent"
              aria-label="Etherscan"
            >
              <img
                id="logo-header"
                width="160"
                src="../assets/images/h-logo.png"
                alt="Etherscan Logo"
              />
            </a>
          </div>
          <div>
            <button
              type="button"
              class="navbar-toggler btn u-hamburger"
              aria-label="Toggle navigation"
              aria-expanded="false"
              aria-controls="navBar"
              data-toggle="collapse"
              data-target="#navBar"
            >
              <span id="hamburgerTrigger" class="u-hamburger__box">
                <span class="u-hamburger__inner"></span>
              </span>
            </button>
          </div>
          <div class="d-flex flex-column w-100">
            <div
              id="navBar"
              class="
                collapse
                navbar-collapse
                u-header__navbar-collapse
                order-md-2
              "
            >
              <ul class="navbar-nav u-header__navbar-nav px-2 px-md-0">
                <li
                  id="LI_default"
                  class="nav-item u-header__nav-item"
                  data-event="hover"
                  data-animation-in="slideInUp"
                  data-animation-out="fadeOut"
                  :class="{ active: index == 1 }"
                >
                  <a
                    class="nav-link u-header__nav-link"
                    href="/"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-labelledby="pagesSubMenu"
                    >Home</a
                  >
                </li>
                <li
                  id="LI_blockchain"
                  class="nav-item hs-has-sub-menu u-header__nav-item"
                  data-event="hover"
                  data-animation-in="slideInUp"
                  data-animation-out="fadeOut"
                  :class="{ active: index == 2 }"
                >
                  <a
                    id="blockchainMegaMenu"
                    class="
                      nav-link
                      u-header__nav-link u-header__nav-link-toggle
                    "
                    href="javascript:;"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-labelledby="pagesSubMenu"
                    >Blockchain</a
                  >
                  <ul
                    id="blockchainSubMenu"
                    class="
                      hs-sub-menu
                      u-header__sub-menu u-header__sub-menu--spacer
                    "
                    aria-labelledby="blockchainMegaMenu"
                    style="min-width: 230px; display: none"
                  >
                    <li id="LI12">
                      <a
                        href="/txs"
                        class="nav-link u-header__sub-menu-nav-link"
                        >View Txns</a
                      >
                    </li>
                    <li id="LI16">
                      <a
                        href="/txsPending"
                        class="nav-link u-header__sub-menu-nav-link"
                        >View Pending Txns</a
                      >
                    </li>
                    <li class="dropdown-divider"></li>
                    <li id="LI_blocks">
                      <a
                        id="navBlocks"
                        class="nav-link u-header__sub-menu-nav-link"
                        href="/blocks"
                        aria-haspopup="true"
                        aria-expanded="false"
                        aria-controls="navSubBlocks"
                        >View Blocks</a
                      >
                    </li>
                    <li class="dropdown-divider"></li>

                    <a
                      id="navBlocks"
                      class="nav-link u-header__sub-menu-nav-link"
                      href="/accounts"
                      aria-haspopup="true"
                      aria-expanded="false"
                      aria-controls="navSubBlocks"
                      >Top Accounts</a
                    >
                  </ul>
                </li>
                <li
                  id="LI_Tokens"
                  class="nav-item hs-has-sub-menu u-header__nav-item"
                  data-event="hover"
                  data-animation-in="slideInUp"
                  data-animation-out="fadeOut"
                  :class="{ active: index == 3 }"
                >
                  <a
                    id="testTokensMegaMenu"
                    class="
                      nav-link
                      u-header__nav-link u-header__nav-link-toggle
                    "
                    href="javascript:;"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-labelledby="testTokensSubMenu"
                    >Tokens</a
                  >
                  <ul
                    id="testTokensSubMenu"
                    class="
                      hs-sub-menu
                      u-header__sub-menu u-header__sub-menu--spacer
                    "
                    aria-labelledby="testTokensMegaMenu"
                    style="min-width: 230px"
                  >
                    <li id="LI18">
                      <a
                        class="nav-link u-header__sub-menu-nav-link"
                        href="/tokens"
                        >PRC20 Top Tokens</a
                      >
                    </li>
                    <li id="LI40">
                      <a
                        class="nav-link u-header__sub-menu-nav-link"
                        href="/transfers"
                        >View PRC20 Transfers</a
                      >
                    </li>
                    <li class="dropdown-divider"></li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <nav
          class="
            js-mega-menu
            navbar navbar-expand-md
            u-header__navbar u-header__navbar--no-space
            hs-menu-initialized hs-menu-horizontal hs-mobile-state
          "
        >
          <div class="w-lg-auto">
            <a
              class="navbar-brand pt-md-0"
              href="/"
              target="_parent"
              aria-label="Etherscan"
            >
              <img
                id="logo-header"
                width="160"
                src="../assets/images/h-logo.png"
                alt="Etherscan Logo"
              />
            </a>
          </div>
          <div>
            <button
              type="button"
              class="navbar-toggler btn u-hamburger"
              @click="ShoeMenu"
              :class="{ collapsed: !isSHowMenu }"
              data-target="#navBar"
            >
              <span id="hamburgerTrigger" class="u-hamburger__box">
                <span class="u-hamburger__inner"></span>
              </span>
            </button>
          </div>
          <div class="d-flex flex-column w-100">
            <div
              id="navBar"
              class="
                collapse
                navbar-collapse
                u-header__navbar-collapse
                order-md-2
              "
              :class="{ show: isSHowMenu }"
            >
              <ul class="navbar-nav u-header__navbar-nav px-2 px-md-0">
                <li
                  id="LI_default"
                  class="nav-item u-header__nav-item"
                  data-event="hover"
                  data-animation-in="slideInUp"
                  data-animation-out="fadeOut"
                  :class="{ active: index == 1 }"
                >
                  <a
                    class="nav-link u-header__nav-link"
                    href="/"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-labelledby="pagesSubMenu"
                    >Home</a
                  >
                </li>
                <li
                  id="LI_blockchain"
                  class="nav-item hs-has-sub-menu u-header__nav-item"
                  data-event="hover"
                  data-animation-in="slideInUp"
                  data-animation-out="fadeOut"
                  :class="{ active: index == 2 }"
                >
                  <a
                    id="blockchainMegaMenu"
                    class="
                      nav-link
                      u-header__nav-link u-header__nav-link-toggle
                    "
                    href="javascript:;"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-labelledby="pagesSubMenu"
                    >Blockchain</a
                  >
                  <ul
                    id="blockchainSubMenu"
                    class="
                      hs-sub-menu
                      u-header__sub-menu u-header__sub-menu--spacer
                    "
                    aria-labelledby="blockchainMegaMenu"
                    style="min-width: 230px; display: none"
                  >
                    <li id="LI12">
                      <a
                        href="/txs"
                        class="nav-link u-header__sub-menu-nav-link"
                        >View Txns</a
                      >
                    </li>
                    <li id="LI16">
                      <a
                        href="/txsPending"
                        class="nav-link u-header__sub-menu-nav-link"
                        >View Pending Txns</a
                      >
                    </li>

                    <li class="dropdown-divider"></li>
                    <li id="LI_blocks">
                      <a
                        id="navBlocks"
                        class="nav-link u-header__sub-menu-nav-link"
                        href="/blocks"
                        aria-haspopup="true"
                        aria-expanded="false"
                        aria-controls="navSubBlocks"
                        >View Blocks</a
                      >
                    </li>
                    <li class="dropdown-divider"></li>
                    <li id="LI_blocks">
                      <a
                        id="navBlocks"
                        class="nav-link u-header__sub-menu-nav-link"
                        href="/accounts"
                        aria-haspopup="true"
                        aria-expanded="false"
                        aria-controls="navSubBlocks"
                        >Top Accounts</a
                      >
                    </li>
                  </ul>
                </li>
                <li
                  id="LI_Tokens"
                  class="nav-item hs-has-sub-menu u-header__nav-item"
                  data-event="hover"
                  data-animation-in="slideInUp"
                  data-animation-out="fadeOut"
                  :class="{ active: index == 3 }"
                >
                  <a
                    id="testTokensMegaMenu"
                    class="
                      nav-link
                      u-header__nav-link u-header__nav-link-toggle
                    "
                    href="javascript:;"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-labelledby="testTokensSubMenu"
                    >Tokens</a
                  >
                  <ul
                    id="testTokensSubMenu"
                    class="
                      hs-sub-menu
                      u-header__sub-menu u-header__sub-menu--spacer
                    "
                    aria-labelledby="testTokensMegaMenu"
                    style="min-width: 230px"
                  >
                    <li id="LI18" class="active">
                      <a
                        class="nav-link u-header__sub-menu-nav-link"
                        href="/tokens"
                        >PRC20 Top Tokens</a
                      >
                    </li>
                    <li id="LI40">
                      <a
                        class="nav-link u-header__sub-menu-nav-link"
                        href="/transfers"
                        >View PRC20 Transfers</a
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isSHowMenu: false,
    };
  },
  props: {
    index: {
      default: 1,
    },
  },
  methods: {
    ShoeMenu() {
      let that = this;
      that.isSHowMenu = !that.isSHowMenu;
    },
  },

  mounted() {
    let that = this;
    $(".hs-mobile-state .u-header__nav-item").click(function () {
      if ($(this).hasClass("hs-sub-menu-opened")) {
        $(this).removeClass("hs-sub-menu-opened");
        $(this).children(".u-header__sub-menu").removeClass("slideInUp");
        $(this).children(".u-header__sub-menu").addClass("fadeOut");
        $(this).children(".u-header__sub-menu").css({ display: "none" });
      } else {
        $(this).addClass("hs-sub-menu-opened");
        $(this).children(".u-header__sub-menu").css({ display: "block" });
        $(this).children(".u-header__sub-menu").removeClass("fadeOut");
        $(this).children(".u-header__sub-menu").addClass("slideInUp");

        $(this).siblings("li").removeClass("hs-sub-menu-opened");
        $(this)
          .siblings("li")
          .children(".u-header__sub-menu")
          .removeClass("slideInUp");
        $(this)
          .siblings("li")
          .children(".u-header__sub-menu")
          .addClass("fadeOut");
        $(this)
          .siblings("li")
          .children(".u-header__sub-menu")
          .css({ display: "none" });
      }
    });

    $(".hs-pc-state .u-header__nav-item").hover(
      function () {
        if ($(this).hasClass("hs-has-mega-menu")) {
          $(this).addClass("hs-mega-menu-opened");
        } else {
          $(this).addClass("hs-sub-menu-opened");
        }

        $(this).children(".u-header__sub-menu").css({ display: "block" });
        $(this).children(".u-header__sub-menu").removeClass("fadeOut");

        $(this).children(".u-header__sub-menu").addClass("slideInUp");
      },
      function () {
        if ($(this).hasClass("hs-has-mega-menu")) {
          $(this).removeClass("hs-mega-menu-opened");
        } else {
          $(this).removeClass("hs-sub-menu-opened");
        }

        $(this).children(".u-header__sub-menu").removeClass("slideInUp");
        $(this).children(".u-header__sub-menu").addClass("fadeOut");
        $(this).children(".u-header__sub-menu").css({ display: "none" });
      }
    );
  },
};
</script>

