import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { ethers } from "ethers";
import { get_detail_by_contract_address } from "../API/api";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/chart/tx",
    name: "chart_tx",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/chart_tx.vue"),
  },
  {
    path: "/blocks",
    name: "blocks",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/blocks.vue"),
  },
  {
    path: "/txs",
    name: "txs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/txs.vue"),
  },
  {
    path: "/accounts",
    name: "accounts",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/accounts.vue"),
  },
  {
    path: "/txs/:height",
    name: "txs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/txs.vue"),
  },
  {
    path: "/txs/:address",
    name: "txs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/txs.vue"),
  },
  {
    path: "/txspending",
    name: "txspending",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/txs_pending.vue"),
  },
  {
    path: "/txspendingdetail/:hash",
    name: "txspendingdetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/txs_pendingdetail.vue"),
  },
  {
    path: "/tx/:hash/:height",
    name: "tx",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/tx.vue"),
  },
  {
    path: "/tx/:hash",
    name: "tx",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/tx.vue"),
  },
  {
    path: "/blocksdetail/:height",
    name: "blocksdetail",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/blocks_detail.vue"),
  },

  {
    path: "/search",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/search.vue"),
  },
  {
    path: "/tokens",
    name: "tokens",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/tokens.vue"),
  },
  {
    path: "/transfers",
    name: "transfers",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/transfers.vue"),
  },

  {
    path: "/verifyaddress",
    name: "verifyaddress",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/verifyAddress.vue"),
  },

  {
    path: "/verifyaddress/manully",
    name: "verifyaddress_manully",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/verifyAddressManully.vue"
      ),
  },

  {
    path: "/sourcecode",
    name: "sourcecode",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/sourcecode.vue"),
  },

  {
    path: "/sourcecode/sure",
    name: "sourcesure",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/sourcesure.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/404.vue"),
  },
];

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.query.address) {
    try {
      ethers.utils.getAddress(to.query.address.trim());
    } catch (error) {
      router.push("/");
      // return;
    }
    // let res = await get_detail_by_contract_address(to.query.address.trim());
    // if (JSON.stringify(res.data) == "{}") {
    //   router.push("/");
    //   return;
    // }
  }
  next();
});

export default router;
