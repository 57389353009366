<template>
  <div>
    <div class="wrapper">
      <comheader></comheader>
      <main id="content" role="main">
        <input type="hidden" name="hdnAgeText" id="hdnAgeText" value="Age" />
        <input
          type="hidden"
          name="hdnDateTimeText"
          id="hdnDateTimeText"
          value="Date Time (UTC)"
        />
        <input
          type="hidden"
          name="hdnAgeTitle"
          id="hdnAgeTitle"
          value="Click to show Age Format"
        />
        <input
          type="hidden"
          name="hdnDateTimeTitle"
          id="hdnDateTimeTitle"
          value="Click to show Datetime Format"
        />
        <input
          type="hidden"
          name="hdnTxnText"
          id="hdnTxnText"
          value="Txn Fee"
        />
        <input
          type="hidden"
          name="hdnGasPriceText"
          id="hdnGasPriceText"
          value="Gas Price"
        />
        <input
          type="hidden"
          name="hdnTxnFeeTitle"
          id="hdnTxnFeeTitle"
          value="(Gas Price * Gas Used by Txns) in Ether"
        />
        <input
          type="hidden"
          name="hdnGasPriceTitle"
          id="hdnGasPriceTitle"
          value="Gas Price in Gwei"
        />
        <section
          class="bg-dark"
          style="
            background-image: url(/images/svg/components/abstract-shapes-20.svg?v=1);
          "
        >
          <div class="container space-top-2 space-bottom-3">
            <div class="row justify-content-between align-items-center mb-4">
              <div class="col-md-12 col-lg-7">
                <div class="pr-lg-4 pr-xll-5">
                  <h1 class="h4 text-white mb-3">
                    The PSC Blockchain Explorer
                  </h1>

                  <div class="input-group input-group-shadow">
                    <div class="input-group-prepend d-none d-md-block">
                      <select
                        name="f"
                        id="f"
                        class="
                          custom-select custom-arrow-select
                          input-group-text
                          font-size-base
                          filterby
                        "
                      >
                        <option selected="" value="0">All Filters</option>
                        <option value="1">Addresses</option>
                        <option value="2">Txhash</option>
                        <option value="3">BlockNum</option>
                        <!--
                          <option value="2">Tokens</option>
                          <option value="3">Name Tags</option>
                          <option value="4">Labels</option>
                          <option value="5">Websites</option>
                          -->
                      </select>
                    </div>
                    <input
                      id="txtSearchInput"
                      type="text"
                      class="
                        form-control
                        searchautocomplete
                        ui-autocomplete-input
                        list-unstyled
                        py-3
                        mb-0
                      "
                      autocomplete="off"
                      spellcheck="false"
                      placeholder="Search by Address / Txn Hash / Block"
                      aria-describedby="button-header-search"
                      maxlength="68"
                      @keyup.enter.prevent="search($event)"
                    />
                    <input type="hidden" value="" id="hdnSearchText" />
                    <input type="hidden" value="" id="hdnSearchLabel" />
                    <input id="hdnIsTestNet" value="False" type="hidden" />
                    <div class="input-group-append">
                      <button
                        class="btn btn-primary"
                        @click="toAnother"
                        style="cursor: pointer"
                      >
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-5">
                <div class="d-none d-lg-block text-center pl-lg-4 pl-xll-5">
                  <a
                    class="d-inline-block d-sm-none position-relative text-dark"
                    target="_blank"
                    href="https://goto.etherscan.com/rd/WNFI4BYTRQ8X1PRA8PWJXTY3P"
                  >
                    <span
                      class="
                        showcase-banner-text
                        bg-white
                        font-size-1
                        shadow
                        rounded
                        px-1
                      "
                      style="left: 1rem"
                      >Ad</span
                    ><img
                      class="img-fluid rounded"
                      src="../assets/images/gen/bybit_600rewards_321x101.png"
                      alt="Ads"
                  /></a>
                  <a
                    class="
                      d-none d-sm-inline-block d-lg-none
                      position-relative
                      text-dark
                    "
                    target="_blank"
                    href="https://goto.etherscan.com/rd/WNFI4BYTRQ8X1PRA8PWJXTY3P"
                  >
                    <span
                      class="
                        showcase-banner-text
                        bg-white
                        font-size-1
                        shadow
                        rounded
                        px-1
                      "
                      style="left: 1rem"
                      >Ad</span
                    ><img
                      class="img-fluid rounded"
                      src="../assets/images/gen/bybit_600rewards_730x90.png"
                      alt="Ads"
                  /></a>
                  <!-- <a
                    class="d-none d-lg-inline-block position-relative text-dark"
                    target="_blank"
                    href="https://goto.etherscan.com/rd/WNFI4BYTRQ8X1PRA8PWJXTY3P"
                  >
                    <span
                      class="
                        showcase-banner-text
                        bg-white
                        font-size-1
                        shadow
                        rounded
                        px-1
                      "
                      style="right: 1rem"
                      >Ad</span
                    ><img
                      class="img-fluid rounded"
                      src="../assets/images/gen/bybit_600rewards_321x101.png"
                      alt="Ads"
                  /></a> -->
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="container space-bottom-1 mt-n5">
          <div id="ContentPlaceHolder1_mainboxes" class="mb-4">
            <div
              class="card-body"
              style="padding-left: 0px; padding-right: 0px"
            >
              <div class="row align-items-center">
                <div class="col-lg-6" style="">
                  <div class="card h-100 p-4" style="overflow: hidden">
                    <div class="media align-items-center">
                      <figure class="u-sm-avatar mr-2">
                        <div class="u-xs-avatar mx-auto">
                          <img
                            src="../assets/images/svg/brands/ethereum-1.svg"
                            alt="Ethereum Logo"
                          />
                        </div>
                      </figure>
                      <div class="media-body">
                        <h2
                          class="font-size-1 text-uppercase text-secondary mb-0"
                        >
                          PSC Price
                        </h2>
                        <a
                          class="text-size-1 text-link"
                          rel="tooltip"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title=""
                          data-original-title="View Historical Ether Price"
                          >{{ nodeData.psc_price }}</a
                        >
                      </div>
                    </div>
                    <hr class="hr-space-lg" />
                    <div class="media align-items-center">
                      <figure class="u-sm-avatar mr-2">
                        <img
                          src="../assets/images/svg/icons/icon-8.svg?v=1.3"
                          alt="SVG"
                        />
                      </figure>
                      <div class="media-body">
                        <h2
                          class="font-size-1 text-uppercase text-secondary mb-0"
                        >
                          Market Cap
                        </h2>
                        <a
                          class="text-size-1 text-link"
                          data-toggle="tooltip"
                          data-placement="top"
                          data-title="View More"
                          href="javascript:;"
                          data-original-title=""
                          title=""
                        >
                          {{ nodeData.psc_all_price }}
                        </a>
                      </div>
                    </div>
                    <hr class="hr-space-lg" />
                    <hr class="d-block d-md-none hr-space-lg" />
                    <div class="media align-items-center">
                      <figure class="u-sm-avatar mr-2">
                        <img
                          src="../assets/images/svg/icons/icon-2-1.svg?v=1.3"
                          alt="Transaction"
                        />
                      </figure>
                      <div class="media-body">
                        <h2
                          class="font-size-1 text-uppercase text-secondary mb-0"
                        >
                          Transactions
                        </h2>
                        <a
                          href="/txs"
                          class="text-size-1 text-link"
                          rel="tooltip"
                          data-toggle="tooltip"
                          data-placement="left"
                          data-html="true"
                          title=""
                          data-original-title="Total Transactions Counter<br>(Update every 5 mins)"
                          >{{ nodeData.transaction_size }} </a
                        ><span
                          class="text-secondary small"
                          rel="tooltip"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title=""
                          data-original-title="Transactions per Second"
                        >
                          (0 TPS)</span
                        >
                      </div>
                      <div
                        class="text-right"
                        title="GasTracker Estimate for Average Gas"
                      >
                        <h2
                          class="font-size-1 text-uppercase text-secondary mb-0"
                        >
                          Med Gas Price
                        </h2>
                        <a
                          class="text-size-1 text-link"
                          data-toggle="tooltip"
                          data-html="true"
                          title=""
                          v-if="gasPrice"
                          data-original-title="Base Fee: 41 Gwei<br>Priority Fee: 2 Gwei"
                          >{{ gasPrice }} Gwei
                        </a>
                      </div>
                    </div>
                    <hr class="hr-space-lg" />
                    <div class="media align-items-center">
                      <figure
                        class="u-sm-avatar mr-2"
                        data-toggle="tooltip"
                        data-placement="top"
                        title=""
                        data-original-title="View Difficulty Growth Chart"
                      >
                        <img
                          src="../assets/images/svg/icons/icon-51.svg"
                          alt="SVG"
                        />
                      </figure>
                      <div class="media-body">
                        <h2
                          class="font-size-1 text-uppercase text-secondary mb-0"
                        >
                          Difficulty
                        </h2>
                        <a
                          class="text-size-1 text-link"
                          data-toggle="tooltip"
                          data-placement="left"
                          data-title="View Difficulty Growth Chart"
                          data-original-title=""
                          title=""
                        >
                         0 TH</a
                        >
                      </div>
                      <div class="text-right">
                        <h2
                          class="font-size-1 text-uppercase text-secondary mb-0"
                        >
                          Hash Rate
                        </h2>
                        <a class="text-size-1 text-link" href="/chart/hashrate">
                          <span
                            rel="tooltip"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title=""
                            data-original-title="Average Hash Rate (The last 12 hours)"
                            ><span id="hashrate">{{ nodeData.hash_rate }}</span>
                            GH/s</span
                          ></a
                        >
                      </div>
                    </div>
                    <hr class="d-block d-md-none hr-space-lg" />
                    <hr class="hr-space-lg" />
                    <hr class="d-none d-md-block d-lg-none my-4" />
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h2
                        class="font-size-1 text-uppercase text-secondary mb-0"
                      >
                        PSC Transaction History in 14 days
                      </h2>
                      <div class="position-relative float-right z-index-2">
                        <a
                          v-if="1 == 0"
                          id="customchartsinvoker"
                          class="btn btn-xs btn-icon btn-soft-secondary"
                          href="javascript:;"
                          role="button"
                          aria-controls="customcharts"
                          aria-haspopup="true"
                          aria-expanded="false"
                          data-unfold-event="click"
                          data-unfold-target="#customcharts"
                          data-unfold-type="css-animation"
                          data-unfold-duration="300"
                          data-unfold-delay="300"
                          data-unfold-hide-on-scroll="true"
                          data-unfold-animation-in="slideInUp"
                          data-unfold-animation-out="fadeOut"
                          @click="isShowCharts"
                        >
                          <i class="fa fa-ellipsis-v btn-icon__inner"></i>
                        </a>
                        <div
                          id="customcharts"
                          class="
                            dropdown-menu dropdown-menu-right dropdown-unfold
                            u-unfold--css-animation
                          "
                          :class="{
                            'fadeOut u-unfold--hidden': showCharts == false,
                            slideInUp: showCharts == true,
                          }"
                          aria-labelledby="customchartsinvoker"
                          style="animation-duration: 300ms; right: 0px"
                        >
                          <a class="dropdown-item" href="/chart/tx"
                            >View Detailed Chart</a
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      id="container-1"
                      class="w-100 pt-2"
                      style="height: 106px"
                      data-highcharts-chart="0"
                    >
                      <highcharts :options="chartOptions"></highcharts>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 h-100" style="caret-color: transparent">
                  <div
                    class="card h-100 p-6"
                    style="overflow: hidden"
                    ref="chartContainer"
                    id="highchartscontainer"
                  >
                    <!-- <img
                      src="../assets/images/model.jpg"
                      alt=""
                      style="width: 100%; height: 100%"
                    /> -->
                    <!--
                    <highcharts :options="chartOptionsPie"></highcharts>
                    -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-block d-lg-none text-center pl-lg-4 pl-xll-5 mb-4">
            <a
              class="d-inline-block d-sm-none position-relative text-dark"
              target="_blank"
              href="https://goto.etherscan.com/rd/WNFI4BYTRQ8X1PRA8PWJXTY3P"
            >
              <span
                class="
                  showcase-banner-text
                  bg-white
                  font-size-1
                  shadow
                  rounded
                  px-1
                "
                style="left: 1rem"
                >Ad</span
              ><img
                class="img-fluid rounded"
                src="../assets/images/gen/bybit_600rewards_321x101.png"
                alt="Ads"
            /></a>
            <a
              class="
                d-none d-sm-inline-block d-lg-none
                position-relative
                text-dark
              "
              target="_blank"
              href="https://goto.etherscan.com/rd/WNFI4BYTRQ8X1PRA8PWJXTY3P"
            >
              <span
                class="
                  showcase-banner-text
                  bg-white
                  font-size-1
                  shadow
                  rounded
                  px-1
                "
                style="left: 1rem"
                >Ad</span
              ><img
                class="img-fluid rounded"
                src="../assets/images/gen/bybit_600rewards_730x90.png"
                alt="Ads"
            /></a>
            <a
              class="d-none d-lg-inline-block position-relative text-dark"
              target="_blank"
              href="https://goto.etherscan.com/rd/WNFI4BYTRQ8X1PRA8PWJXTY3P"
            >
              <span
                class="
                  showcase-banner-text
                  bg-white
                  font-size-1
                  shadow
                  rounded
                  px-1
                "
                style="right: 1rem"
                >Ad</span
              ><img
                class="img-fluid rounded"
                src="../assets/images/gen/bybit_600rewards_321x101.png"
                alt="Ads"
            /></a>
          </div>

          <div class="row mb-5">
            <div class="col-lg-6 mb-4 mb-lg-0">
              <div class="card h-100">
                <div class="card-header">
                  <h2 class="card-header-title">Latest Blocks</h2>
                </div>

                <div class="home-scroll card-body">
                  <div
                    id="mCSB_1"
                    class="
                      mCustomScrollBox
                      mCS-minimal-dark
                      mCSB_vertical mCSB_outside
                    "
                    style="max-height: none"
                    tabindex="0"
                  >
                    <div
                      id="mCSB_1_container"
                      class="mCSB_container"
                      style="position: relative; top: 0; left: 0"
                      dir="ltr"
                    >
                      <template v-for="(item, index) in blockList">
                        <div class="row" style="" :key="index">
                          <div class="col-sm-4">
                            <div
                              class="
                                media
                                align-items-sm-center
                                mr-4
                                mb-1 mb-sm-0
                              "
                            >
                              <div class="d-none d-sm-flex mr-2">
                                <span class="btn btn-icon btn-soft-secondary"
                                  ><span class="btn-icon__inner text-dark"
                                    >Bk</span
                                  ></span
                                >
                              </div>
                              <div class="media-body">
                                <span class="d-inline-block d-sm-none"
                                  >Block</span
                                >
                                <a :href="'/blocksdetail/' + item.height">{{
                                  item.height
                                }}</a
                                ><span
                                  class="
                                    d-sm-block
                                    small
                                    text-secondary
                                    ml-1 ml-sm-0
                                    text-nowrap
                                  "
                                  id="cd13080370"
                                  data-countdown="8000"
                                  >{{ item.created_at_format }}</span
                                >
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-8">
                            <div class="d-flex justify-content-between">
                              <div class="text-nowrap">
                                <span class="d-block mb-1 mb-sm-0"
                                  >Block Hash
                                  <a
                                    class="hash-tag text-truncate"
                                    :href="'/blocksdetail/' + item.height"
                                    >{{ item.hash }}</a
                                  ></span
                                ><a
                                  :href="'/txs?height=' + item.height"
                                  title="Transactions in this Block"
                                  >{{ item.tx_size }} txns</a
                                >
                                <span
                                  v-if="1 == 0"
                                  class="small text-secondary"
                                >
                                  in 8 secs</span
                                ><span class="d-inline-block d-sm-none"
                                  ><span
                                    class="
                                      u-label
                                      u-label--xs
                                      u-label--badge-in
                                      u-label--secondary
                                      text-center text-nowrap
                                    "
                                    title="Block Reward"
                                    >0 {{ nodeData.code }}</span
                                  ></span
                                >
                              </div>
                              <div class="d-none d-sm-block">
                                <span
                                  class="
                                    u-label
                                    u-label--xs
                                    u-label--badge-in
                                    u-label--secondary
                                    text-center text-nowrap
                                  "
                                  title="Block Reward"
                                  >0 {{ nodeData.code }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr
                          class="hr-space"
                          :key="item.height"
                          v-if="index + 1 != blockList.length"
                        />
                      </template>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <a
                    class="btn btn-xs btn-block btn-soft-primary"
                    href="/blocks"
                    >View all blocks</a
                  >
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card h-100">
                <div class="card-header">
                  <h2 class="card-header-title">Latest Transactions</h2>
                </div>
                <div class="home-scroll card-body">
                  <div
                    id="mCSB_2"
                    class="
                      mCustomScrollBox
                      mCS-minimal-dark
                      mCSB_vertical mCSB_outside
                    "
                    tabindex="0"
                    style="max-height: none"
                  >
                    <div
                      id="mCSB_2_container"
                      class="mCSB_container"
                      style="position: relative; left: 0px"
                      dir="ltr"
                    >
                      <template v-for="(item, index) in transactionList">
                        <div class="row" :key="index">
                          <div class="col-sm-4">
                            <div
                              class="
                                media
                                align-items-sm-center
                                mr-4
                                mb-1 mb-sm-0
                              "
                            >
                              <div class="d-none d-sm-flex mr-2">
                                <span
                                  class="
                                    btn btn-icon btn-soft-secondary
                                    rounded-circle
                                  "
                                  ><span class="btn-icon__inner text-dark"
                                    >Tx</span
                                  ></span
                                >
                              </div>
                              <div class="media-body">
                                <span class="d-inline-block d-sm-none mr-1"
                                  >Tx#</span
                                ><a
                                  class="
                                    hash-tag hash-tag--xs
                                    hash-tag-xs-down--md
                                    text-truncate
                                  "
                                  :href="
                                    '/tx/' + item.hash + '/' + item.block_height
                                  "
                                  >{{ item.hash }}</a
                                ><span
                                  class="d-none d-sm-block small text-secondary"
                                  ><div
                                    id="cd0xc791e3c25007a7054f9af16f64faf66815ca2b48eda7bf675e7796e79c68daed"
                                    data-countdown="8000"
                                  >
                                    {{ item.created_at_format }}
                                  </div></span
                                >
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-8">
                            <div class="d-sm-flex justify-content-between">
                              <div class="text-nowrap mr-4 mb-1 mb-sm-0">
                                <span
                                  >From
                                  <a
                                    class="hash-tag text-truncate"
                                    :href="'/txs?address=' + item.from"
                                    >{{ item.from }}</a
                                  ></span
                                ><span class="d-sm-block"
                                  >To
                                  <a
                                    :href="'/txs?address=' + item.to"
                                    class="hash-tag hash-tag--sm text-truncate"
                                    >{{ item.to }}</a
                                  ></span
                                >
                              </div>
                              <div>
                                <span
                                  class="
                                    u-label
                                    u-label--xs
                                    u-label--badge-in
                                    u-label--secondary
                                    text-center text-nowrap
                                  "
                                  title="Amount"
                                  >0 {{ nodeData.code }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr
                          class="hr-space"
                          :key="item.hash"
                          v-if="index + 1 != blockList.length"
                        />
                      </template>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <a class="btn btn-xs btn-block btn-soft-primary" href="/txs"
                    >View all transactions</a
                  >
                </div>
              </div>
            </div>

            <div class="col-lg-6" v-if="false">
              <div class="card h-100">
                <div class="card-header">
                  <h2 class="card-header-title">Latest Transactions</h2>
                </div>
                <div class="home-scroll card-body">
                  <div
                    id="mCSB_2"
                    class="
                      mCustomScrollBox
                      mCS-minimal-dark
                      mCSB_vertical mCSB_outside
                    "
                    tabindex="0"
                    style="max-height: none"
                  >
                    <div
                      id="mCSB_2_container"
                      class="mCSB_container"
                      style="position: relative; left: 0px"
                      dir="ltr"
                    >
                      <template v-for="(item, index) in transactionListNew">
                        <div class="row" :key="index">
                          <div class="col-sm-4">
                            <div
                              class="
                                media
                                align-items-sm-center
                                mr-4
                                mb-1 mb-sm-0
                              "
                            >
                              <div class="d-none d-sm-flex mr-2">
                                <span
                                  class="
                                    btn btn-icon btn-soft-secondary
                                    rounded-circle
                                  "
                                  ><span class="btn-icon__inner text-dark"
                                    >Tx</span
                                  ></span
                                >
                              </div>
                              <div class="media-body">
                                <span class="d-inline-block d-sm-none mr-1"
                                  >Tx#</span
                                ><a
                                  class="
                                    hash-tag hash-tag--xs
                                    hash-tag-xs-down--md
                                    text-truncate
                                  "
                                  :href="
                                    '/tx/' + item.hash + '/' + item.block_height
                                  "
                                  >{{ item.hash }}</a
                                ><span
                                  class="d-none d-sm-block small text-secondary"
                                  ><div
                                    id="cd0xc791e3c25007a7054f9af16f64faf66815ca2b48eda7bf675e7796e79c68daed"
                                    data-countdown="8000"
                                  >
                                    {{ item.created_at_format }}
                                  </div></span
                                >
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-8">
                            <div class="d-sm-flex justify-content-between">
                              <div class="text-nowrap mr-4 mb-1 mb-sm-0">
                                <span
                                  >From
                                  <a
                                    class="hash-tag text-truncate"
                                    :href="'/txs?address=' + item.from"
                                    >{{ item.from }}</a
                                  ></span
                                ><span class="d-sm-block"
                                  >To
                                  <a
                                    :href="'/txs?address=' + item.to"
                                    class="hash-tag hash-tag--sm text-truncate"
                                    >{{ item.to }}</a
                                  ></span
                                >
                              </div>
                              <div>
                                <span
                                  class="
                                    u-label
                                    u-label--xs
                                    u-label--badge-in
                                    u-label--secondary
                                    text-center text-nowrap
                                  "
                                  title="Amount"
                                  >0 {{ nodeData.code }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr
                          class="hr-space"
                          :key="item.hash"
                          v-if="index + 1 != blockList.length"
                        />
                      </template>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <a class="btn btn-xs btn-block btn-soft-primary" href="/txs"
                    >View all transactions</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div id="push"></div>
      <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        id="emailSubscribeModalBox"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button
                type="button"
                class="close close-md"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div class="text-center py-5-alt px-4">
                <div id="emailSubscribeModalBoxText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <comfooter></comfooter>
  </div>
</template>

<script>
// @ is an alias to /src
import comheader from "@/components/comheader";
import comfooter from "@/components/comfooter";
import { Chart } from "highcharts-vue";
import HighCharts from "highcharts";
import Web3 from "web3";
import {rpc} from "@/contract/address.json";
import highcharts3d from "highcharts/highcharts-3d";
import {
  get_node_info,
  get_block_list,
  get_transaction_list,
  get_dayTrans_info,
  get_transaction_by_from_or_to,
  get_transaction_by_hash,
  get_block_by_height,
} from "@/API/api";
import { timeFrom, getDate } from "@/Utils/timeFrom";

export default {
  name: "Home",
  components: {
    comheader,
    comfooter,
    highcharts: Chart,
  },
  data() {
    return {
      gasPrice:'',
      addr: "",
      nodeData: {
        code: "psc",
        hash_rate: "0",
        node_url: "http://172.22.144.75:3000",
        psc_all_price: "$0",
        psc_price: "$0.0000",
        transaction_size: 0,
      }, //获取节点信息
      blockList: [], //10条最新的区块列表
      transactionList: [], //10条最新的事务
      transactionListNew: [],
      showCharts: false,
      page: 0,
      per_page: 25,
      chartOptions: {
        chart: {
          spacingTop: 10,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 10,
          height: 96,
        },

        title: {
          text: "",
          align: "left",
        },

        xAxis: {
          title: { text: "" },
          lineWidth: 0,
          minorTickLength: 0,
          tickLength: 0,
          labels: {
            step: 7,
          },
          categories: [],
        },

        yAxis: {
          labels: {
            enabled: true,
          },
          gridLineWidth: 0,
          title: {
            text: null,
          },
        },

        legend: {
          enabled: false,
        },

        credits: {
          enabled: false,
        },

        tooltip: {
          formatter: function(e) {
            //let obj = data.chart
            let s =
              '<span style="font-size:10px">' +
              this.point.friendlydate +
              '</span><br><table><tr><td style="padding:0">' +
              '<span style="color:' +
              this.series.color +
              '">Transactions: </a></span><b>' +
              this.point.formattedValue +
              "</b><br>" +
              "<br>" +
              "Price: " +
              this.point.price +
              "";
            ("</td></tr></table>");
            return s;
          },
        },

        plotOptions: {
          series: {
            color: "#1e2022",
            enableMouseTracking: true,
            lineWidth: 1,
            shadow: false,
            animation: false,
            cursor: "pointer",
            states: {
              hover: {
                lineWidth: 1,
              },
            },
            marker: {
              radius: 0,
            },
            point: {
              events: {
                select: function(e) {
                  location.href = "txs?dt=" + this.options.dt;
                },
              },
            },
          },
          column: {
            pointPadding: 0.1,
            borderWidth: 0,
          },
        },

        series: [
          {
            name: "Transactions",
            type: "spline",
            data: [],
            allowPointSelect: true,
            pointStart: 0,
          },
        ],
      },
      chartOptionsPie: {
        chart: {
          type: "pie",
          options3d: {
            enabled: true,
            alpha: 45,
          },
        },

        title: {
          text: "PSC Token Distribution",
          style: { color: "#4a4f55", fontSize: "1.2rem", fontWeight: "900" },
        },

        plotOptions: {
          pie: {
            // innerSize: 100,
            depth: 75,
          },
        },
        series: [
          {
            size: "100%",
            name: "",
            data: [
              // ["79%Consensus Reward", 7.9],
              // ["20% Polyland Release (not yet start)", 2],
              // ["1% Mining Release (not yet start)", 0.1],
              { y: 0.79, name: "79% Consensus Reward", color: "#24365E" },
              {
                y: 0.2,
                name: "20% Polyland Release (not yet start)",
                color: "#379ADC",
              },
              {
                y: 0.01,
                name: "1% Mining Release (not yet start)",
                color: "#AAB1BB",
              },
            ],
          },
        ],
        credits: { enabled: false },
      },
    };
  },
  filters: {
    str_Address(address) {
      let str1 = address.slice(0, 4);
      let str2 = address.slice(-4);

      return str1 + "..." + str2;
    },
    timeFromat(date) {
      return timeFrom(date);
    },

    decimal(v) {
      if (v == "") {
        return;
      }
      const decimals = 9;
      const chars = "0123456789";
      if (v.isZero()) return "0";
      let i = 1;
      let ret = "";
      let base = ethers.utils.bigNumberify(10);
      let allZero = true;
      while (!v.isZero() || i <= decimals) {
        let remainder = v.mod(base);
        v = v.div(base);
        let ch = remainder.toNumber();
        allZero = allZero && ch == 0;
        if (allZero && i <= decimals) {
        } else {
          ret = chars.charAt(ch) + ret;
        }
        if (i == decimals) {
          if (!allZero) {
            ret = "." + ret;
          }
          if (v.isZero()) {
            ret = "0" + ret;
          }
        }
        i++;
      }
      return ret;
    },
  },
  mounted() {
    let that = this;
    that.get_gasPrice()
    that.getNodeData();
    that.get_block_list();
    that.get_transaction_list();
    that.get_dayTrans_info();

    that.$nextTick(function() {
      highcharts3d(HighCharts);
      HighCharts.chart(highchartscontainer, that.chartOptionsPie);
    });
  },
  methods: {
    async getNodeData() {
      let that = this;
      get_node_info().then((res) => {
        that.nodeData = res;
      });
    },
    timeFromat(date) {
      return timeFrom(date);
    },

    async get_dayTrans_info() {
      let that = this;

      // {
      //           y: 1201544,
      //           dt: "1629244800",
      //           formattedValue: "1,201,544",
      //           friendlydate: "Wednesday, August 18, 2021",
      //           price: "$3,013.52",
      //         }

      get_dayTrans_info().then((res) => {
        var arr = Object.keys(res);
        var arrX = [];
        var arrY = [];
        for (let i = 0; i < arr.length; i++) {
          let time = arr[i];
          let timeObj = getDate(time);
          arrX.push(timeObj.monthLess + " " + timeObj.day);

          let yObj = {};
          yObj.y = res[time];
          yObj.dt = time;
          yObj.formattedValue = res[time];
          yObj.friendlydate =
            timeObj.week +
            "，" +
            timeObj.monthFull +
            " " +
            timeObj.day +
            "，" +
            timeObj.year;
          yObj.price = "$0.00";
          arrY.push(yObj);
        }
        that.chartOptions.xAxis.categories = arrX;
        that.chartOptions.series[0].data = arrY;
      });
    },
    async get_gasPrice (){
    let that = this; 
    let web3 = new Web3(rpc)
    const gasPrice = await web3.eth.getGasPrice(); 
    that.gasPrice = `${web3.utils.fromWei(gasPrice, 'gwei')}` 
  },

    async get_block_list() {
      let that = this;
      get_block_list(10, 0).then((res) => {
        that.blockList = res.data.content;
        that.blockList.forEach((item) => {
          item.created_at_format = that.timeFromat(item.created_at);
        });
      });
    },
    async get_transaction_list() {
      let that = this;
      get_transaction_list(10, 0).then((res) => {
        that.transactionList = res.data.content;
        that.transactionList.forEach((item) => {
          item.created_at_format = that.timeFromat(item.created_at);
        });
      });
      // get_transaction_list(10, 1).then((res) => {
      //   that.transactionListNew = res.data.content;
      //   that.transactionListNew.forEach((item) => {
      //     item.created_at_format = that.timeFromat(item.created_at);
      //   });
      // });
    },
    isShowCharts() {
      let that = this;
      that.showCharts = !that.showCharts;
    },
    toAnother() {
      let that = this;
      let textOld = document.querySelector("#txtSearchInput").value;
      if (textOld == "") return;
      let text = textOld.trim();
      var value = document.querySelector("#f").value;
      if (value == 0) {
        if (!that.isNumber(text)) {
          if (text.length < 45) {
            get_transaction_by_from_or_to(that.per_page, that.page, text).then(
              (res) => {
                that.$router.push("/txs?address=" + text);
              }
            );
          } else {
            get_transaction_by_hash(text).then((res) => {
              that.$router.push("/tx/" + text);
            });
          }
        } else {
          get_block_by_height(text).then((res) => {
            that.$router.push("/blocksdetail/" + text);
          });
        }
      } else if (value == 1) {
        get_transaction_by_from_or_to(that.per_page, that.page, text).then(
          (res) => {
            that.$router.push("/txs?address=" + text);
          }
        );
      } else if (value == 2) {
        get_transaction_by_hash(text).then((res) => {
          that.$router.push("/tx/" + text);
        });
      } else if (value == 3) {
        if (!(parseInt(text, 10) == text)) {
          text = "100000000";
        }
        get_block_by_height(text).then((res) => {
          that.$router.push("/blocksdetail/" + text);
        });
      }
    },
    search(e) {
      let that = this;
      var keyCode = window.event ? e.keyCode : e.which;
      if (keyCode == 13) {
        that.toAnother();
      }
    },
    isNumber(val) {
      var regPos = /^\d+(\.\d+)?$/; //非负浮点数
      var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
      if (regPos.test(val) || regNeg.test(val)) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.highcharts-credits {
  display: none;
}
</style>
