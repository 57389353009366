<template>
  <footer
    class="bg-dark py-4"
    style="
      background-image: url(../assets/images/svg/components/abstract-shapes-20.svg?v=1);
    "
  >
    <div class="container">
      <!-- <div class="row justify-content-between align-items-center font-size-1">
          <div class="col-md-12 mb-2 mb-md-0">
            <p class="mb-0 text-white" style="text-align:center">
              {{this.$store.state.project_title}} © 2021 (A1)&nbsp; 
             
            </p>
          </div>
    
        </div> -->

      <div class="container">
        <div
          class="row justify-content-md-between font-size-1 py-2"
          style="background: url(/images/world-map-white.png) no-repeat 0 30%"
        >
          <div class="col-lg-3 mb-4 mb-lg-0">
            <div class="d-flex h-100 flex-column">
              <div class="d-flex align-items-center mb-4">
                <img width="20" src="../assets/images/logo.png" />
                <span class="h4 text-white mb-0 ml-3"
                  >Powered by Poly Smart Chain</span
                >
              </div>
              <p class="text-white">
                Pscscan is a Block Explorer and Analytics Platform for Poly
                Smart Chain, a decentralized smart contracts platform.
              </p>

              <!-- <div class="d-flex align-items-center">
                <a class="btn btn-xss btn-soft-light mr-2" href="/settings">
                  <i class="fa fa-cogs mr-1"></i>Preferences
                </a>
                <button
                  id="darkModaBtn"
                  type="button"
                  data-toggle="tooltip"
                  data-title="Day/Night Mode"
                  class="btn btn-sm btn-icon btn-soft-light"
                  data-original-title=""
                  title=""
                >
                  <i id="darkModaBtnIcon" class="fa fa-moon"></i>
                </button>
              </div> -->
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-2 mb-4 mb-lg-0">
            <h4 class="h6 text-white heading-border">Company</h4>
            <ul
              class="
                list-group
                list-group-sm
                list-group-transparent
                list-group-white
                list-group-flush
                list-group-borderless
                mb-0
              "
            >
              <li>
                <a class="list-group-item" href="javascript:;">About Us</a>
              </li>
              <li>
                <a class="list-group-item" href="javascript:;">Contact Us</a>
              </li>
              <li>
                <a class="list-group-item" href="javascript:;">Brand Assets</a>
              </li>
              <li>
                <a class="list-group-item" href="javascript:;"
                  >Careers<sup
                    ><span class="badge badge-success font-size-default ml-1">
                      Join Us</span
                    ></sup
                  ></a
                >
              </li>
              <li>
                <a class="list-group-item" href="javascript:;"
                  >Terms of Service</a
                >
              </li>
            </ul>
          </div>
          <div class="col-6 col-md-4 col-lg-2 mb-4 mb-lg-0">
            <h4 class="h6 text-white heading-border">Resources</h4>
            <ul
              class="
                list-group
                list-group-sm
                list-group-transparent
                list-group-white
                list-group-flush
                list-group-borderless
                mb-0
              "
            >
              <li>
                <a class="list-group-item" href="javascript:;"
                  >API Documentation</a
                >
              </li>
              <li>
                <a class="list-group-item" href="javascript:;"
                  >Knowledge Base</a
                >
              </li>
              <li>
                <a class="list-group-item" href="javascript:;">Newsletter</a>
              </li>
              <li>
                <a
                  class="list-group-item"
                  rel="nofollow noopener"
                  href="javascript:;"
                  >Network Status</a
                >
              </li>
              <li>
                <a class="list-group-item" href="javascript:;"
                  >Disqus Comments</a
                >
              </li>
            </ul>
          </div>
          <div class="col-6 col-md-4 col-lg-2 mb-4 mb-lg-0">
            <h4 class="h6 text-white heading-border">
              Products &amp; Services
            </h4>
            <ul
              class="
                list-group
                list-group-sm
                list-group-transparent
                list-group-white
                list-group-flush
                list-group-borderless
                mb-0
              "
            >
              <li>
                <a class="list-group-item" href="javascript:;">Advertise</a>
              </li>
              <li>
                <a class="list-group-item" href="javascript:;"
                  >Explorer-as-a-Service (EaaS)</a
                >
              </li>
              <li>
                <a
                  class="list-group-item"
                  href="javascript:;"
                  title="Developer APIs"
                  >Developer APIs</a
                >
              </li>
              <li>
                <a
                  class="list-group-item"
                  href="javascript:;"
                  title="Decentralized Web (.ens,.crypto) Search Engine"
                  >Blockscan</a
                >
              </li>
              <li>
                <a
                  class="list-group-item"
                  href="javascript:;"
                  title="Beacon Chain Explorer for Ethereum 2.0"
                  >BeaconScan</a
                >
              </li>
            </ul>
          </div>
        </div>
        <hr class="opacity-md" />
        <div class="row justify-content-between align-items-center font-size-1">
          <div class="col-md-6 mb-2 mb-md-0">
            <p class="mb-0 text-white">
              Pscscan © 2021 (A1)&nbsp; | &nbsp;<a
                class="text-white-70"
                href="javascript:;"
                >Donate</a
              >
              <i class="fas fa-heart text-danger"></i>
            </p>
          </div>
          <div class="col-md-6 text-md-right">
            <ul class="list-inline mb-0">
              <li class="list-inline-item">
                <a
                  class="btn btn-sm btn-icon btn-soft-light btn-pill"
                  href="javascript:;"
                  rel="nofollow noopener"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Twitter"
                >
                  <i class="fab fa-twitter btn-icon__inner"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  class="btn btn-sm btn-icon btn-soft-light btn-pill"
                  href="javascript:;"
                  rel="nofollow noopener"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Facebook"
                >
                  <i class="fab fa-facebook btn-icon__inner"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  class="btn btn-sm btn-icon btn-soft-light btn-pill"
                  href="javascript:;"
                  rel="nofollow noopener"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Reddit"
                >
                  <i class="fab fa-reddit-alien btn-icon__inner"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  class="btn btn-sm btn-icon btn-soft-light btn-pill"
                  href="javascript:;"
                  rel="nofollow noopener"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Medium"
                >
                  <i class="fab fa-medium btn-icon__inner"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style >
.fab {
  font-family: "FontAwesome";
}
</style>
