import axios from "./http";

// var get_node_info = function (data,header) {
// 	return axios.post('/get_node_info' ,data,{headers: {'Content-Type':'application/json','header':header}})
// }

var get_node_info = function() {
  return axios.get("/get_node_info");
};

var get_block_list = function(per_page, page) {
  return axios.get("/get_block_list?per_page=" + per_page + "&page=" + page);
};

var get_transaction_list = function(per_page, page) {
  return axios.get(
    "/get_transaction_list?per_page=" + per_page + "&page=" + page
  );
};

var get_block_by_hash = function(hash) {
  return axios.get("/get_block_by_hash?block_hash=" + hash);
};

var get_block_by_height = function(height) {
  return axios.get("/get_block_by_height?height=" + height);
};

var get_transaction_by_hash = function(hash) {
  return axios.get("/get_transaction_by_hash?tx_hash=" + hash);
};

var get_dayTrans_info = function() {
  return axios.get("/get_more_days_transaction_counts");
};

var get_pending_block = function(per_page, page) {
  return axios.get("/get_pending_block?per_page=" + per_page + "&page=" + page);
};

var get_transaction_by_block_height = function(per_page, page, height) {
  return axios.get(
    "/get_transaction_by_block_height?per_page=" +
      per_page +
      "&page=" +
      page +
      "&height=" +
      height
  );
};

var get_transaction_by_from_or_to = function(per_page, page, address) {
  return axios.get(
    "/get_transaction_by_from_or_to?per_page=" +
      per_page +
      "&page=" +
      page +
      "&address=" +
      address
  );
};

var get_token_list = function(per_page, page) {
  return axios.get(
    "/get_contract_asset_list?per_page=" + per_page + "&page=" + page
  );
};

var get_transfer_list = function(per_page, page) {
  return axios.get(
    "/get_asset_transaction?per_page=" + per_page + "&page=" + page
  );
};

var get_transaction_by_hash_height = function(hash, height) {
  return axios.get(
    "/get_transaction_by_hash_height?tx_hash=" + hash + "&height=" + height
  );
};

var get_account_balance = function(hash) {
  return axios.get("/account/" + hash);
};

var get_detail_by_contract_address = function(address) {
  return axios.get("/get_detail_by_contract_address?address=" + address);
};

var if_contract_address = function(address) {
  return axios.get("/is_contract?contract_address=" + address);
};

var get_asset_transaction_by_address = function(
  address,
  per_page,
  page,
  token
) {
  return axios.get(
    "/get_asset_transaction_by_address?address=" +
      address +
      "&per_page=" +
      per_page +
      "&page=" +
      page +
      "&token=" +
      token
  );
};

var update_code_info = function(obj) {
  return axios.post("/update_code_info", obj, {
    headers: { "Content-Type": "application/json" },
  });
};

var upload_contract_source_code = function(obj) {
  return axios.post("/upload_contract_source_code", obj, {
    headers: { "Content-Type": "application/json" },
  });
};

var get_contract_source_code = function(address) {
  return axios.get("/get_contract_source_code?contract_address=" + address);
};

var get_psc_list = function(per_page, page) {
  return axios.get("/get_psc_list?per_page=" + per_page + "&page=" + page);
};
var get_wpsc_list = function(per_page, page) {
  return axios.get("/get_wpsc_list?per_page=" + per_page + "&page=" + page);
};

export {
  get_psc_list,
  get_wpsc_list,
  get_node_info,
  get_block_list,
  get_transaction_list,
  get_dayTrans_info,
  get_block_by_hash,
  get_transaction_by_hash,
  get_block_by_height,
  get_pending_block,
  get_transaction_by_block_height,
  get_transaction_by_from_or_to,
  get_token_list,
  get_transfer_list,
  get_transaction_by_hash_height,
  get_account_balance,
  get_detail_by_contract_address,
  get_asset_transaction_by_address,
  if_contract_address,
  update_code_info,
  upload_contract_source_code,
  get_contract_source_code,
};
