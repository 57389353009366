/**
 * 时间戳转为多久之前
 * @param String timestamp 时间戳
 **/
var timeFrom = function(dateTime) {
  // 如果为null,则格式化当前时间
  if (!dateTime) dateTime = Number(new Date());
  // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
  if (dateTime.toString().length == 10) dateTime *= 1000;
  let timestamp = +new Date(Number(dateTime));

  let timer = (Number(new Date()) - timestamp) / 1000;
  // 如果小于5分钟,则返回"刚刚",其他以此类推
  let tips = "";
  switch (true) {
    case timer < 60:
      if (Number(timer) < 0) {
        timer = 0;
      }
      tips = timer.toFixed(0) + " secs ago";

      break;
    case timer >= 60 && timer < 3600:
      tips = parseInt(timer / 60) + " mins ago";
      break;
    case timer >= 3600 && timer < 86400:
      tips = parseInt(timer / 3600) + " hrs ago";
      break;
    case timer >= 86400 && timer < 2592000:
      tips = parseInt(timer / 86400) + " days ago";
      break;
    default:
      if (timer >= 2592000 && timer < 365 * 86400) {
        tips = parseInt(timer / (86400 * 30)) + " months ago";
      } else {
        tips = parseInt(timer / (86400 * 365)) + " years ago";
      }
  }

  return tips;
};

var getDate = function(timestamp) {
  let date = new Date(timestamp * 1000);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let time = {};
  time.year = year;
  time.month = month;
  time.day = day;

  if (month == 1) {
    time.monthFull = "January";
    time.monthLess = "Jan";
  } else if (month == 2) {
    time.monthFull = "February";
    time.monthLess = "Feb";
  } else if (month == 3) {
    time.monthFull = "March";
    time.monthLess = "Mar";
  } else if (month == 4) {
    time.monthFull = "April";
    time.monthLess = "Apr";
  } else if (month == 5) {
    time.monthFull = "May";
    time.monthLess = "May";
  } else if (month == 6) {
    time.monthFull = "June";
    time.monthLess = "Jun";
  } else if (month == 7) {
    time.monthFull = "July";
    time.monthLess = "Jul";
  } else if (month == 8) {
    time.monthFull = "August";
    time.monthLess = "Aug";
  } else if (month == 9) {
    time.monthFull = "September";
    time.monthLess = "Sept";
  } else if (month == 10) {
    time.monthFull = "October";
    time.monthLess = "Oct";
  } else if (month == 11) {
    time.monthFull = "November";
    time.monthLess = "Nov";
  } else if (month == 12) {
    time.monthFull = "December";
    time.monthLess = "Dec";
  }

  let weekDay = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let week = weekDay[date.getDay()];
  time.week = week;

  return time;
};

export { timeFrom, getDate };
